import en from "../locales/en_GB.json";
import fr from "../locales/fr.json";
import de from "../locales/de.json";
import it from "../locales/it.json";
import { createI18n } from "vue-i18n";

const getLocale = () => {
    const userConfig = JSON.parse(localStorage.getItem("user-config"));

    if (!userConfig) {
        return null;
    }

    // An annoying case in the API is that locales are
    // in the form `en_GB` instead of `en-GB` so we replace
    // the `_` with a `-` to ensure the JS `Intl.DateTimeFormat` functions
    // work correctly.
    return userConfig.language.replace("_", "-");
};

const instance = createI18n({
    locale: getLocale() || "en-GB",
    fallbackLocale: "en-GB",
    messages: {
        "en-GB": en,
        fr,
        de,
        it,
    },
    legacy: false,
    datetimeFormats: {
        "en-GB": {
            short: {
                year: "numeric",
                month: "short",
                day: "numeric",
            },
            long: {
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
                hour: "numeric",
                minute: "numeric",
            },
            date: {
                year: "numeric",
                month: "short",
                day: "numeric",
            },
            timestamp: {
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
            },
        },
    },
});

export default instance;
